<template>
    <div class="common-table search-result">
        <div class="common-table__title">按药品分析
            <div class="flex-item"></div>
            <div class="ts-search">
                <span style="font-size: 14px;">筛选：</span>
                <el-select v-model="comCode" filterable remote reserve-keyword placeholder="请输入药品关键词" :remote-method="remoteMethod"
                           :loading="selectLoading" style="width: 300px" @change="changeFun">
                    <el-option v-for="item in options" :key="item.id" :label="item.comName" :value="item.comCode"></el-option>
                </el-select>
            </div>
        </div>
        <div v-if="isEmpty" class="search-result__container" style="margin-top: 20px;padding-top: 100px;">
            <ts-empty></ts-empty>
        </div>
        <div v-else class="search-result-body">
            <el-tabs v-model="tab" type="border-card">
                <el-tab-pane name="year"><span slot="label">{{ pageMap.year }}</span></el-tab-pane>
                <el-tab-pane name="quarter"><span slot="label">{{ pageMap.quarter }}</span></el-tab-pane>
                <el-tab-pane name="corp"><span slot="label">{{ pageMap.corp }}</span></el-tab-pane>
                <el-tab-pane name="rate"><span slot="label">{{ pageMap.rate }}</span></el-tab-pane>
            </el-tabs>
        </div>

        <div class="search-result__container" v-if="loaded && !isEmpty">
            <div class="search-result__panel" :class="{show: tab === 'year'}">
                <drug-year v-if="finished.year" :visit-id="visitId" :com-code="comCode"></drug-year>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'quarter'}">
                <drug-quarter v-if="finished.quarter" :visit-id="visitId" :com-code="comCode"></drug-quarter>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'corp'}">
                <drug-corp v-if="finished.corp" :visit-id="visitId" :com-code="comCode" :base-query="{comCode: comCode}"></drug-corp>
            </div>
            <div class="search-result__panel" :class="{show: tab === 'rate'}">
                <drug-rate v-if="finished.rate" :visit-id="visitId" :com-name="comName" :base-query="{comCode: comCode}"></drug-rate>
            </div>
        </div>
    </div>
</template>

<script>
    import DrugCorp from './DrugCorp'
    import DrugQuarter from './DrugQuarter'
    import DrugRate from './DrugRate'
    import DrugYear from './DrugYear'
    export default {
        components: {DrugCorp, DrugQuarter,DrugRate,DrugYear},
        data() {
            return {
                visitId: 0,
                pageId: '',
                pageName: '',
                pageMap: {
                    year: '药品历年销售趋势',
                    quarter: '药品历年季度销售趋势',
                    corp: '药品企业销售',
                    rate: '药品剂型销售占比',
                },

                tab: 'year',
                loaded: false,
                finished: {
                    corp: false,
                    quarter: false,
                    year: false,
                    rate: false,
                },
                isEmpty: false,
                loading: false,

                comName: '',
                comCode: '',
                selectLoading: false,
                options: [],
            }
        },
        created() {
            this.comCode = this.$route.query.comCode
            this.init()
            this.$store.commit('loading')
        },
        watch: {
            '$route': function () {
                this.init()
            },
            tab: {
                handler(val) {
                    this.finished[val] = true

                    this.visitId = new Date().getTime()
                    this.pageId = val
                    this.pageName = '药品分析-' + this.pageMap[val]

                    this.$help.socket.send(this)
                },
                immediate: true
            },
        },
        methods: {
            init() {
                this.isEmpty = false
                this.remoteMethod(this.$route.query.comCode || '氯化钠')
            },
            remoteMethod(keyword) {
                this.$api.get('setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword', {keyword}).then(res => {
                    this.options = res.data
                    if (!this.comCode) {
                        this.comCode = 'YP1000009166-1'
                    }
                    this.loaded = true
                    this.$store.commit('loaded')
                });
            },
            changeFun() {
                this.loaded = false
                this.$nextTick(() => {
                    this.loaded = true
                })
            },
        },
    }
</script>